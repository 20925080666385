<template>
  <div @click="$emit('click')" class="small-cancel-button margin-cancel-button">
    <div v-if="type != 'cancel'" :class="getClass" class="icon-button">
      <button :title="getTitle" class="button">&nbsp;</button>
    </div>
    <vca-cancel-button :title="getTitle" class="icon-button" v-else />
  </div>
</template>
<script>
export default {
  name: "IconButton",
  props: {
    type: {
      type: String,
      default: "confirm",
    },
    title: {
      type: String,
      default: null,
    },
  },
  computed: {
    getClass() {
      return this.type + "-button";
    },
    getTitle() {
      return this.title ? this.title : this.$t("button." + this.type);
    },
  },
};
</script>
<style lang="scss">
.small-cancel-button {
  width: 41px !important;
  height: 41px !important;
  @include media(large) {
    width: 48px !important;
    height: 48px !important;
  }

  .cancel-button {
    height: 100%;
  }
}

.margin-cancel-button {
  margin: auto 10px auto 0;
}

.edit-button {
  button {
    background-color: $white;
    color: $blue;
    &:hover {
      background-color: $blue;
      color: $white;
      border: solid thin $blue;
    }
    &::after {
      content: "✎";
    }
  }
}
.confirm-button {
  button {
    color: $green;
    background-color: $white;
    &::after {
      content: "✔";
    }
    &:hover {
      background-color: $green;
      color: $white;
      border: solid thin $green;
    }
  }
}
.restore-button {
  button {
    background-color: $white;
    color: $blue;
    &::after {
      content: "➯";
    }
    &:hover {
      background-color: $blue;
      color: $white;
      border: solid thin $blue;
    }
  }
}
.view-button {
  button {
    background-color: $white;
    color: $blue;
    &:hover {
      &::after {
        content: "👁";
      }
      background-color: $blue;
      color: $white;
      border: solid thin $blue;
    }
  }
}

.icon-button {
  height: 48px;
  width: 48px;
  display: flex;
  position: relative;
  margin-right: 10px;

  button {
    height: 100%;
    text-decoration: none;
    font-weight: bold;
    text-transform: uppercase;
    line-height: 2.5em;
    cursor: pointer;
    padding: 0 15px;
    width: 100%;
    border: solid thin transparent;
    transition: 0.3s;
  }
}
</style>
