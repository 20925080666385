<template>
  <div>
    <vca-dropdown
      v-model="select"
      :options="crewList"
      label=""
      :placeholder="$t('taking.filter.crew.placeholder')"
    ></vca-dropdown>
    <button class="vca-button" :disabled="select.length == 0" @click="add()">
      {{ $t("taking.filter.crew.button") }}
    </button>
    <div class="tags-container">
      <div class="tag" v-for="(res, index) in selected" :key="res.id">
        <div class="tag-label">{{ res.name }} ({{ res.abbreviation }})</div>
        <div class="cancel" @click="remove(index)">X</div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  name: "CrewFilterSelection",
  props: {
    value: {
      type: Array,
      default: function () {
        return [];
      },
    },
  },
  data() {
    return {
      select: [],
      selected: [],
    };
  },
  created() {
    this.$store
      .dispatch({ type: "crews/public" })
      .then(() => {
        this.selected = this.crewList.filter(({ id }) =>
          this.value.includes(id)
        );
      })
      .catch();
  },
  computed: {
    ...mapGetters({
      crewList: "crews/dropdown",
    }),
  },
  methods: {
    add() {
      if (this.value === undefined) {
        this.value = [this.select[0].id];
      } else {
        this.value.push(this.select[0].id);
      }
      this.selected.push(this.select[0]);
      this.$emit("input", this.value);
    },
    remove(index) {
      const val = this.value.filter((e) => e !== this.selected[index].id);
      this.selected.splice(index, 1);
      this.$emit("input", val);
    },
  },
};
</script>
