<template>
  <div>
    <h3>{{ $t("deposit.external.organisation.label") }}</h3>
    <vca-input
      v-model="value.organisation"
      :placeholder="$t('deposit.external.organisation.placeholder')"
      @input="updateValue"
    ></vca-input>
    <h3>{{ $t("deposit.external.asp.label") }}</h3>
    <vca-input
      v-model="value.asp"
      :placeholder="$t('deposit.external.asp.placeholder')"
      @input="updateValue"
    ></vca-input>
    <h3>{{ $t("deposit.external.email.label") }}</h3>
    <vca-input
      v-model="value.email"
      :placeholder="$t('deposit.external.email.placeholder')"
      @input="updateValue"
    ></vca-input>
    <h3>{{ $t("deposit.external.address.label") }}</h3>
    <vca-input
      v-model="value.address"
      :placeholder="$t('deposit.external.address.placeholder')"
      @input="updateValue"
    ></vca-input>
    <h3>{{ $t("deposit.external.purpose.label") }}</h3>
    <vca-input
      v-model="value.purpose"
      :placeholder="$t('deposit.external.purpose.placeholder')"
      @input="updateValue"
    ></vca-input>
  </div>
</template>
<script>
export default {
  name: "DepositExternal",
  props: {
    value: {
      type: Object,
      default: function () {
        return {
          organisation: "",
          asp: "",
          email: "",
          address: "",
          reciept: false,
          purpose: "",
        };
      },
    },
  },
  methods: {
    updateValue() {
      this.$emit("input", this.value);
    },
  },
};
</script>
