<template>
  <div>
    <div class="table-container" :key="value.deposit_units.length">
      <VcaTable
        store="takings"
        :list="pg_list"
        :colspan="7"
        :searchLabel="$t('taking.list.search')"
      >
        <template v-slot:colspan>
          <col width="12%" />
          <col width="12%" />
          <col width="12%" />
          <col width="4%" />
          <col width="4%" />
          <col width="4%" />
          <col width="1%" />
        </template>
        <template v-slot:desktop-filter>
          <vca-accordion :header="$t('taking.list.filter')">
            <div slot="body">
              <TakingFilter v-model="filter" @reset="resetFilter()" />
            </div>
          </vca-accordion>
        </template>
        <template v-slot:desktop-header>
          <th class="vca-table-cell sortable" @click="sort('name')">
            <label> {{ $t("taking.list.name") }} &varr;</label>
          </th>
          <th class="vca-table-cell">
            <label> {{ $t("taking.list.to_event") }} &varr;</label>
          </th>
          <th class="vca-table-cell">
            <label> {{ $t("taking.list.open") }} &varr;</label>
          </th>
          <th class="vca-table-cell">
            <label> {{ $t("taking.list.wait") }} &varr;</label>
          </th>
          <th class="vca-table-cell">
            <label> {{ $t("taking.list.confirmed") }} &varr;</label>
          </th>

          <th class="vca-table-cell sortable" @click="sort('crew.name')">
            <label> {{ $t("taking.list.crew_name") }} &varr;</label>
          </th>
          <th class="vca-table-cell">
            <label>{{ $t("table.options") }}</label>
          </th>
        </template>
        <template v-slot:desktop-content>
          <tr
            class="vca-table-row"
            @click="setCurrent(res)"
            :class="{ last: index + 1 == pg.pageSize }"
            v-for="(res, index) in pg_list"
            :key="index"
          >
            <td class="vca-table-cell">
              <a :href="takingLink(res.id)"> {{ takingName(res) }}</a>
            </td>
            <td class="vca-table-cell">
              <a :href="eventLink(res.event.id)"> {{ res.event.name }}</a>
            </td>
            <td class="vca-table-cell">
              <label>
                {{ formatMoneyStatus(res.state.open, res.state.no_income) }}
              </label>
            </td>
            <td class="vca-table-cell">
              <label>
                {{ formatMoneyStatus(res.state.wait, res.state.no_income) }}
              </label>
            </td>
            <td class="vca-table-cell">
              <label>
                {{
                  formatMoneyStatus(res.state.confirmed, res.state.no_income)
                }}
              </label>
            </td>

            <td class="vca-table-cell">
              <label> {{ res.crew.name }} </label>
            </td>
            <td class="vca-table-cell">
              <label class="table-options">
                <IconButton
                  @click="add(res)"
                  :title="$t('button.add')"
                  v-if="!selected(res) && res.state.open.amount > 0"
                />
                <IconButton
                  type="cancel"
                  :title="$t('button.remove')"
                  @click="remove(res)"
                  v-if="selected(res) && res.state.open.amount > 0"
                />
              </label>
            </td>
          </tr>
        </template>
        <template v-slot:mobile-filter>
          <button class="vca-button" @click="do_filter = true">
            {{ $t("taking.list.filter") }}
          </button>
          <vca-popup
            v-if="do_filter"
            :show="do_filter"
            :title="$t('taking.list.filter')"
            @close="do_filter = false"
          >
            <TakingFilter v-model="filter" />
          </vca-popup>
        </template>
        <template v-slot:mobile-header>
          <div>
            <img width="20px" src="~@/assets/icons/sort.png" />
          </div>
          <div class="sortable" @click="sort('profile.full_name')">
            <label>{{ $t("taking.list.name") }} &varr;</label>
          </div>
          <div class="sortable" @click="sort('email')">
            <label>{{ $t("taking.list.email") }} &varr;</label>
          </div>
        </template>
        <template v-slot:mobile-content>
          <tr
            class="vca-table-row"
            :class="{ last: index + 1 == pg.pageSize }"
            v-for="(res, index) in pg_list"
            :key="index"
          >
            <td class="vca-table-cell">
              <vca-column>
                <vca-row>{{ res.id }} </vca-row>
              </vca-column>
            </td>
          </tr>
        </template>
      </VcaTable>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapState } from "vuex";
import IconButton from "@/components/utils/IconButton";
import VcaTable from "@/components/utils/VcaTable";
import TakingFilter from "@/components/finance/takings/list/TakingFilter";
export default {
  name: "DepositAddTable",
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: { VcaTable, TakingFilter, IconButton },
  data() {
    return {
      store: "takings/pg",
      initial: true,
      do_filter: false,
      currentFilter: {},
      currentTextFilter: "",
      valueLocal: [],
    };
  },
  computed: {
    filter: {
      set(value) {
        this.pg.filterObject = value;
      },
      get() {
        return this.pg.filterObject;
      },
    },
    ...mapState({
      pg(state, getters) {
        if (this.store) {
          return getters[`${this.store}/pagination`];
        }
      },
    }),
    ...mapGetters({
      query: "takings/query",
      pg_list: "takings/pg/list",
    }),
  },

  watch: {
    "value.crew_id": {
      handler() {
        this.reload_takings();
      },
    },
    "pg.currentPage": {
      handler() {
        this.$store.dispatch({ type: `${this.store}/request` });
      },
      deep: true,
    },
    "pg.pageSize": {
      handler(value) {
        this.$store.commit(`${this.store}/pageSize`, value);
        if (this.pg.currentPageSize == 1) {
          this.$store.dispatch({ type: `${this.store}/request` });
        }
      },
      deep: true,
    },
    "pg.filterObject": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
    "pg.filter": {
      handler() {
        this.$store.dispatch({
          type: `${this.store}/request`,
          data: { inital: true, abort: true },
        });
      },
      deep: true,
    },
  },
  created() {
    this.reload_takings();
  },
  methods: {
    formatMoneyStatus(value, no_income) {
      if (no_income) {
        return this.$t("taking.list.no_income");
      } else {
        return value.amount / 100 + " €";
      }
    },
    selected(value) {
      return this.value.deposit_units.find((el) => el.taking_id == value.id);
    },
    add(value) {
      var depositUnit = {
        taking_id: value.id,
        money: value.state.open,
        taking: {
          name: value.name,
        },
      };
      if (depositUnit.money.currency === "") {
        depositUnit.money.currency = "EUR";
      }
      this.value.deposit_units.push(depositUnit);
      if (this.value.crew_id === "") {
        this.value.crew_id = value.crew_id;
        this.reload_takings();
      }
    },
    reload_takings() {
      var filter = { status_open: "true" };
      if (this.value.crew_id !== "") {
        filter.crew_id = this.value.crew_id;
      }
      this.filter = filter;
    },
    remove(value) {
      var fValue = this.value.deposit_units.filter((v) => {
        return v.taking_id !== value.id;
      });
      this.value.deposit_units = fValue;
      this.$emit("input", this.value);
    },
    formatMoney(value) {
      return value.amount / 100 + " €";
    },
    setCurrent(value) {
      if (this.current && this.current.id == value.id) {
        this.$store.commit("takings/current", null);
      } else {
        this.$store.commit("takings/current", value);
      }
    },
    emptyFilter() {
      return !this.pg.filter || this.pg.filter == "";
    },
    async sort(col) {
      await this.$store.dispatch({
        type: this.store + "/sort",
        col,
      });
      return;
    },
  },
};
</script>
<style lang="scss">
.selectecd {
  background-color: green;
}
</style>
