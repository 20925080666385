<template>
  <vca-row>
    <div>
      <vca-row>
        <vca-column>
          <vca-field :label="$t('taking.filter.search.header')">
            <h3>{{ $t("taking.filter.search.label.name") }}</h3>
            <vca-input
              v-model="filter.search"
              :label="$t('taking.filter.search.label.name')"
              :placeholder="$t('taking.filter.search.placeholder.name')"
            >
            </vca-input>
          </vca-field>
          <vca-field
            v-if="hasSystemPermission()"
            :label="$t('taking.filter.crew.header')"
          >
            <CrewFilterSelection v-model="filter.crew_id" />
          </vca-field>
        </vca-column>
        <vca-field :label="$t('taking.filter.status.header')">
          <vca-row>
            <vca-checkbox v-model="filter.status_open" id="open">
              {{ $t("taking.filter.status.open") }}
            </vca-checkbox>
            <vca-checkbox v-model="filter.status_wait" id="wait">
              {{ $t("taking.filter.status.wait") }}
            </vca-checkbox>
          </vca-row>
          <vca-row>
            <vca-checkbox v-model="filter.status_confirmed" id="confirmed">
              {{ $t("taking.filter.status.confirmed") }}
            </vca-checkbox>
            <vca-checkbox v-model="filter.status_none" id="none">
              {{ $t("taking.filter.status.none") }}
            </vca-checkbox>
          </vca-row>
          <vca-row>
            <vca-checkbox v-model="filter.status_no_income" id="no_income">
              {{ $t("taking.filter.status.no_income") }}
            </vca-checkbox>
          </vca-row>
        </vca-field>
      </vca-row>
      <div class="vca-center">
        <vca-cancel-button
          @click="reset"
          :placeholder="$t('table.filter.reset')"
        />
      </div>
    </div>
  </vca-row>
</template>
<script>
import { mapGetters } from "vuex";
import CrewFilterSelection from "../../../filter/CrewFilterSelection.vue";
export default {
  name: "TakingFilter",
  props: {
    value: {
      type: Object,
      default: null,
    },
  },
  data() {
    return {
      select: [],
    };
  },
  computed: {
    filter: {
      set(value) {
        this.$emit("input", value);
      },
      get() {
        return this.value;
      },
    },
    ...mapGetters({
      hasSystemPermission: "user/roles/hasSystemPermission",
    }),
  },
  methods: {
    reset() {
      this.filter_all_roles = false;
      this.$emit("reset");
    },
  },
  components: { CrewFilterSelection },
};
</script>
