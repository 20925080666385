<template>
  <vca-card v-if="finish">
    <h2>{{ $t("deposit.edit.header") }}</h2>
    <DepositEdit />
  </vca-card>
</template>
<script>
import DepositEdit from "@/components/finance/deposits/DepositEdit.vue";
import { mapGetters } from "vuex";
export default {
  name: "DepositEditView",
  components: {
    DepositEdit,
  },
  data() {
    return {
      finish: false,
    };
  },
  created() {
    this.create().then(() => (this.finish = true));
  },
  methods: {
    async create() {
      await this.$store.dispatch({
        type: "deposits/getByID",
        data: this.$route.params.id,
      });
      await this.$store.dispatch({ type: "crews/public" });
    },
  },
  computed: {
    ...mapGetters({
      edit: "deposits/edit",
    }),
  },
};
</script>
