<template>
  <div>
    <vca-field :label="$t('deposit.edit.info')">
      <h3>{{ $t("deposit.edit.crew") }} {{ value.crew.name }}</h3>
      <h3>
        {{ $t("deposit.edit.reason_for_payment") }}
        {{ value.reason_for_payment }}
      </h3>
    </vca-field>

    <vca-field :label="$t('deposit.add.takings')">
      <div v-if="!value.deposit_units.length">
        {{ $t("deposit.add.description") }}
      </div>
      <div v-else style="color: black; float: right">
        <vca-info-box>{{ $t("deposit.add.description") }}</vca-info-box>
      </div>
      <vca-row :key="index" v-for="(res, index) in value.deposit_units">
        <span> {{ res.taking.name }} </span>
        <span>{{ formatMoney(res.money) }}</span>
      </vca-row>
    </vca-field>
    <vca-field :label="$t('deposit.external.header')">
      <DepositExternal v-if="value.has_external" v-model="value.external" />
    </vca-field>
  </div>
</template>
<script>
import DepositExternal from "./DepositExternal.vue";

export default {
  name: "DepositInfo",
  props: {
    value: {
      type: Object,
      default: function () {
        return {};
      },
    },
  },
  components: { DepositExternal },
};
</script>
